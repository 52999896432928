<template>
  <div>
    <filter-data :get-filter-data="getDataList" />
    <loading v-if="loadingReport === true" />
    <report-table v-show="loadingReport === false" />
  </div>
</template>

<script>
import FilterData from '@/views/Reports/Sales/SalesByCity/FilterData.vue'
import ReportTable from '@/views/Reports/Sales/SalesByCity/ReportTable.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Index',
  components: {
    FilterData,
    ReportTable,
    Loading,
  },
  computed: {
    filterData() {
      return this.$store.getters['salesByCity/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['salesByCity/getLoading']
    },
  },
  methods: {
    getDataList() {
      this.$store.dispatch('salesByCity/report', this.filterData)
    },
  },
}
</script>
