<template>
  <b-card no-body>
    <b-card-text>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        small
      >
        <template #cell(brand)="data">
          {{ data.item.brand }}
          <div class="font-small-2 text-warning">
            {{ data.item.model }}
          </div>
          <div class="font-small-2 text-muted">
            Satış Föy No: {{ data.item.order_number }}
          </div>
        </template>
        <template #cell(invoice_date)="data">
          {{ moment(data.item.invoice_date).format('LL') }}
          <div class="font-small-2 text-primary">
            {{ data.item.invoice_no }}
          </div>
        </template>
        <template #cell(chasis)="data">
          {{ data.item.chasis }}
          <div class="font-small-2 text-danger">
            {{ data.item.engine }}
          </div>
        </template>
        <template #cell(customer_name)="data">
          {{ data.item.customer_name }}
          <div class="font-small-2 text-muted">
            {{ data.item.company_name }}
          </div>
          <div class="font-small-2 text-success">
            {{ data.item.phone }}
          </div>
        </template>
        <template #cell(city)="data">
          {{ data.item.city }}
          <div class="font-small-2 text-danger">
            {{ data.item.district }}
          </div>
        </template>
        <template #cell(otvrate)="data">
          <div class="text-danger text-center">
            %{{ data.item.otvrate }}
          </div>
        </template>
      </b-table>
    </b-card-text>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTable, BCardFooter, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BCardFooter,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'brand',
          label: 'MARKA MODEL',
        },
        {
          key: 'invoice_date',
          label: 'FATURA TARIHI',
        },
        {
          key: 'chasis',
          label: 'ŞASE',
        },
        {
          key: 'customer_name',
          label: 'MÜŞTERİ',
        },
        {
          key: 'city',
          label: 'İL - İLÇE',
        },
        {
          key: 'otvrate',
          label: 'ÖTV ORANI',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['salesByCity/getFilterData']
    },
    dataList() {
      return this.$store.getters['salesByCity/getReport']
    },
    dataCount() {
      return this.$store.getters['salesByCity/getDataCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.filterData.start = page
      if (this.filterData.start === page) {
        this.getDataList()
      }
    },
  },
  methods: {
    getDataList() {
      this.$store.dispatch('salesByCity/report', this.filterData)
    },
  },
}
</script>
